import { GameStatus } from "@/prisma/schema/mysql";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";
import { FC } from "react";
import { MdPlusOne } from "react-icons/md";

const ProcessingStatus = () => (
  <div className="flex justify-center h-9 w-full p-2 bg-slate-100 rounded border border-amber-500 items-center gap-3">
    <div className="justify-start items-center gap-2 flex">
      <div className="w-5 h-5 relative">
        <ExclamationCircleIcon className="fill-amber-500" />
      </div>
      <div className="text-amber-500 text-sm font-normal leading-tight">
        Processing
      </div>
    </div>
  </div>
);

const CompletedStatus = () => (
  <div className="flex justify-center h-9 w-full p-2 bg-slate-100 rounded border border-cyan-500 items-center gap-3">
    <div className="justify-start items-center gap-2 flex">
      <div className="w-5 h-5 relative">
        <CheckCircleIcon className="fill-cyan-500" />
      </div>
      <div className="text-cyan-500 text-sm font-normal leading-tight">
        Completed
      </div>
    </div>
  </div>
);

const SuccessStatus = () => (
  <div className="flex justify-center h-9 w-full p-2 bg-slate-100 rounded border border-green-500 items-center gap-3">
    <div className="justify-start items-center gap-2 flex">
      <div className="w-5 h-5 relative">
        <CheckCircleIcon className="fill-green-500" />
      </div>
      <div className="text-green-500 text-sm font-normal leading-tight">
        Success
      </div>
    </div>
  </div>
);

const FailedStatus = () => (
  <div className="flex justify-center h-9 w-full p-2 bg-slate-100 rounded border border-red-500 items-center gap-3">
    <div className="flex items-center border-r border-slate-400 pr-2">
      <div className="justify-start items-center gap-2 flex">
        <div className="w-5 h-5 relative">
          <ExclamationTriangleIcon className="fill-red-500" />
        </div>
        <div className="text-red-500 text-sm font-normal leading-tight">
          Failed
        </div>
      </div>
    </div>
    <div className="text-blue-500 text-sm font-normal leading-tight hover:underline">
      Retry
    </div>
  </div>
);

const RefundedStatus = () => {
  return (
    <div className="flex justify-center h-9 w-full p-2 bg-slate-100 rounded border border-emerald-700 items-center gap-3">
      <div className="justify-start items-center gap-2 flex">
        <div className="flex gap-1">
          <SparklesIcon className="w-4 h-4 text-amber-500" />
          <MdPlusOne className="w-4 h-4 text-amber-500" />
        </div>
        <div className="text-emerald-700 text-sm font-normal leading-tight">
          Refunded
        </div>
      </div>
    </div>
  );
};
interface GamestatusPopoverProps {
  status: GameStatus;
  handleResubmit: () => void;
  absolute?: boolean;
  refunded?: boolean;
}

export const GamestatusPopover: FC<GamestatusPopoverProps> = ({
  status,
  handleResubmit,
  absolute,
  refunded = false,
}) => {
  let renderComp = () => <></>;
  let renderExtra = () => <></>;
  if (status === "FAILED") renderComp = FailedStatus;
  if (status === "PROCESSING") renderComp = ProcessingStatus;
  if (status === "SUCCESS") renderComp = SuccessStatus;
  if (status === "COMPLETED") renderComp = CompletedStatus;
  if (refunded) renderExtra = RefundedStatus;

  const handleClick = (e: any) => {
    if (status === "FAILED") {
      e.preventDefault();
      handleResubmit();
    }
  };

  return (
    <div
      className={clsx(
        "w-fit p-1 gap-1 flex flex-col",
        absolute && "absolute flex flex-col bottom-0 w-full ",
      )}
      onClick={handleClick}
    >
      {renderComp()}
      {renderExtra()}
    </div>
  );
};
