export type I18nConfig = {
  locales: string[];
  defaultLocale: string;
};

const i18nConfig: I18nConfig = {
  locales: ["en", "ro"],
  defaultLocale: "en",
};

export default i18nConfig;
